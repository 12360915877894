@font-face {
  font-family: matrixFont;
  src: url(./matrix\ code\ nfi.ttf);
}
.App {
  text-align: center;
  margin: 10px;
  justify-items: center;
}
nav {
  display: flex;
  background-color: darksalmon;
  justify-content: space-between;
  align-items: center;
  border: solid salmon;
  border-radius: 5px;
}
nav a {
  margin: 10px;
  text-decoration: none;
  color: black;
  border-radius: 5px;
}
nav a:hover {
  padding: 10px;
  background-color: salmon;
}
.catLink {
  margin: 10px;
  text-decoration: none;
  color: black;
  border: solid black;
  border-radius: 5px;
  background-color: salmon;
  padding: 20px;
}
.HomePage {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: darksalmon;
  border: solid;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  justify-items: center;
}
.ModalStyle {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 2fr;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: antiquewhite;
  border: solid;
  border-radius: 10px;
  padding: 25px;
  grid-gap: 5px;
  column-gap: 10px;
}
.catImageModal {
  width: 300px;
  grid-column: 1;
  grid-row: 1/3;
}
.catName {
  grid-column: 2;
  grid-row: 1;
}
.catPrice {
  grid-column: 2;
  grid-row: 2;
}
.catBreed {
  grid-column: 2;
  grid-row: 3;
}
.catGender {
  grid-column: 2;
  grid-row: 4;
}
.checkoutAdd {
  grid-column: 2;
  grid-row: 5/7;
  background-color: darksalmon;
  font-size: xx-large;
}
.checkoutRemove {
  grid-column: 2;
  grid-row: 5/7;
  background-color: darksalmon;
  font-size: xx-large;
}
.checkoutAdd:hover {
  background-color: lightgreen;
}
.checkoutRemove:hover {
  background-color: lightcoral;
}
.ownerDetails {
  grid-column: 1;
  grid-row: 3;
}
.ownerName {
  grid-column: 1;
  grid-row: 4;
}
.ownerBio {
  grid-column: 1;
  grid-row: 5;
}
.ownerEmail {
  grid-column: 1;
  grid-row: 6;
}
.catWindow {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border-radius: 5px;
  padding: 5px;
}
.Cat {
  display: grid;
  object-fit: contain;
  justify-content: center;
  justify-items: center;
  align-content: center;
  border: solid;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
}
.Cat:hover {
  background-color: salmon;
}
.catImage {
  width: 200px;
  height: 300px;
  object-fit: contain;
}
.CatDetails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

#checkout {
  text-align: center;
}

.cartRemove {
  border-radius: 10px;
}

.cartRemove:hover {
  background-color: rgb(223, 24, 24);
  color: white;
}
.carousel-root {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
